import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import Layout from '@/layouts';
import mainLayout from '@/layouts/mainLayout';
// import echartRouter from './modules/echarts';
// import componentsRouter from './modules/components';
// import nestRouter from './modules/nest';

//基础路由
export const baseRoute = [
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  // {
  //   name: 'register',
  //   path: '/register',
  //   component: () => import('@/views/register/index'),
  //   hidden: true
  // },
  {
    path: '/403',
    component: () => import('@/views/error/403'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '/500',
    component: () => import('@/views/error/500'),
    hidden: true
  }
];

export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/enterprise',
    change: 'root',
    children: [
      {
        path: '/enterprise',
        component: mainLayout,
        redirect: '/enterprise/index',
        change: 'enterprise',
        meta: {
          title: '企业管理',
          icon: 'appstore',
          base: true
        },
        children: [
          {
            name: 'EnterpriseManager',
            path: '/enterprise/index',
            component: () => import('@/views/enterprise/modules/enterprise.vue'),
            meta: {
              role: 'HT:enterprise',
              title: '企业管理',
              icon: 'file-add'
            }
          },
          {
            name: 'agentManage',
            path: '/agent/index',
            component: () => import('@/views/enterprise/modules/agentManage/index.vue'),
            meta: {
              title: '线索管理',
              icon: 'file-zip',
              role: 'HT:thread'
            }
          },
          {
            name: 'customManager',
            path: '/custom/index',
            component: () => import('@/views/enterprise/modules/customManage/index.vue'),
            meta: {
              title: '客户管理',
              icon: 'file-zip',
              role: 'HT:customer',
              base: true
            }
          },

          {
            name: 'customerAddModel',
            path: '/custom/index/hide-detail',
            component: () => import('@/views/enterprise/modules/customManage/followDetail.vue'),
            meta: {
              title: '跟进客户详情',
              icon: 'file-zip',
              base: true
            },
            hidden: true
          },
          {
            name: 'seaManager',
            path: '/sea/index',
            component: () => import('@/views/enterprise/modules/seaManage/index.vue'),
            meta: {
              title: '公海客户',
              icon: 'file-zip',
              role: 'HT:GHcustomer'
            }
          },

          {
            name: 'accountManage',
            path: '/account/index',
            component: () => import('@/views/enterprise/modules/accountManage/index.vue'),
            meta: {
              title: '员工管理',
              icon: 'file-zip',
              role: 'HT:user'
            }
          },
          {
            name: 'authManage',
            path: '/auth/index',
            component: () => import('@/views/enterprise/modules/authManage/index.vue'),
            meta: {
              title: '权限管理',
              icon: 'file-zip',
              role: 'HT:authority'
            }
          },
          {
            name: 'shareManage',
            path: '/share/index',
            component: () => import('@/views/enterprise/modules/shareManage/index.vue'),
            meta: {
              title: '分享管理',
              icon: 'file-zip',
              role: 'HT:authority'
            }
          },
          {
            name: 'shareDetail',
            path: '/share/index/hide-detail',
            component: () => import('@/views/enterprise/modules/shareManage/detail.vue'),
            meta: {
              title: '推荐详情页',
              icon: 'file-zip',
              role: 'HT:authority'
            },
            hidden:true
          }
        ]
      }
    ]
  },
  { path: '*', redirect: '/404', hidden: true }
];

const createRouter = function () {
  return new VueRouter({
    routes: baseRoute,
    scrollBehavior: () => ({ y: 0 })
  });
};

const router = createRouter();

export function resetRouter() {
  router.matcher = createRouter().matcher;
}

//重定向时报错，用这个不让他报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

export default router;
